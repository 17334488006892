import React from 'react'
import bannerEn from '../../images/ondemand/banner_en@2x.png'
import bannerCn from '../../images/ondemand/banner_cn@2x.png'
import bannerId from '../../images/ondemand/banner_id@2x.png'
import bannerVi from '../../images/ondemand/banner_vi@2x.png'

function OnDemandBanner({ locale }) {

  function getBannerUrl() {
    switch (locale) {
      case 'en':
        return bannerEn
      case 'cn':
        return bannerCn
      case 'id':
        return bannerId
      case 'vi':
        return bannerVi

      default:
        return bannerEn
    }
  }

  return (
    <div className="relative w-full flex items-center justify-center overflow-hidden">
      <img className="object-cover max-h-md w-full" src={getBannerUrl()} alt={''} />
    </div>
  )
}

export default React.memo(OnDemandBanner)
