import React, { useState, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import userAuth from '../../utils/userAuth'
import ErrorPopup from './ErrorPopup'
import parse from 'html-react-parser'
import whitePlayButton from '../../images/ondemand/white_play_btn@2x.png'
import whitePlayButton3x from '../../images/ondemand/white_play_btn@3x.png'
import whitePlayButton4x from '../../images/ondemand/white_play_btn@4x.png'

import Checkbox from "../Checkbox"
import { classNames } from 'utils'

function LoginLayout() {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [currentState, setCurrentState] = useState(0)
  const [openPopup, setOpenPopup] = useState(false)

  function reducer(state, action) {
    const updatedValues = state
    if ('password' in action) {
      updatedValues.password = action.password
      updatedValues.error = false
    }
    if ('agree' in action) {
      updatedValues.agree = action.agree
      updatedValues.error = false
    }
    if ('error' in action) {
      updatedValues.error = action.error
    }
    if ('render' in action) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }
  const [values, dispatch] = useReducer(reducer, { password: '', agree: false, error: '' })
  const { password, agree, error } = values

  function onFormSubmit(e) {
    e.preventDefault()
    handleSubmit()
  }

  async function handleSubmit() {
    if (!password) {
      return
    }
    if (!agree) {
      return
    }
    try {
      if (userAuth.checkLogin(password)) {
        const token = userAuth.generateToken()
        userAuth.setAuth({ auth_token: token })
        window.location.href = `/${language}`
      } else {
        dispatch({
          error: t('login.login_error'),
          render: true
        })
        setOpenPopup(true)
      }
    } catch (error) {
      dispatch({
        error: t('login.login_error'),
        render: true
      })
      setOpenPopup(true)
    }
  }

  function handleChangeInput(value) {
    dispatch({ password: value.target.value, render: true })
  }

  return (
    <div className="md:w-11/12 lg:w-footer-rate mx-4  sm:mx-auto h-full flex flex-col justify-between min-h-97">
      <div className="w-full flex flex-col md:flex-row h-full items-center">
        <div className="w-full flex justify-center">
          <form
            onSubmit={(e) => onFormSubmit(e)}
            className="w-full flex flex-col pb-5 sm:pb-9 pt-10 sm:pt-20 px-1 md:px-7 items-center justify-start"
          >
            <div className="w-full max-w-3xl flex flex-col items-center justify-start">
              <div className="w-full flex flex-col rounded-2xl overflow-hidden shadow-lg">
                <div className="bg-secondary text-white text-2xl md:text-3xl lg:text-5xl font-bold leading-snug flex w-full items-center justify-center p-3">
                  <span>{t('login.on_demand')}</span>
                  <img className="inline w-8 ml-3" src={whitePlayButton} alt="" srcSet={`${whitePlayButton} 768w, ${whitePlayButton3x} 1280w, ${whitePlayButton4x} 1920w`} />
                </div>
                <div className="flex flex-col w-full justify-center items-center p-8">
                  <div className="text-lg md:text-2xl text-general-gray font-bold mb-4">
                    {t('login.please_key_in_password')}
                  </div>
                  <input
                    className="rounded-full max-w-md w-full bg-gray-border text-white placeholder-white h-10 md:h-12  px-10 text-lg md:text-2xl outline-none text-center"
                    placeholder={t('login.password')}
                    value={password}
                    onChange={(value) => handleChangeInput(value)}
                    type="password"
                  />
                </div>
              </div>

              <div className="max-w-xl md:max-w-2xl w-full flex flex-col items-center mt-4 md:mt-8 px-0 md:px-4">
                <div className="relative w-full flex px-2 sm:px-4 md:px-12 justify-center text-center text-general-gray text-lg md:text-2xl">
                  <div className="inline font-bold">
                    <Checkbox id="agree_box" defaultValue={agree} name="agree" onChange={(value) => dispatch({ agree: value, render: true })} />
                  </div>
                  <label htmlFor="agree_box" className="font-bold">{t('login.checkbox_label')}</label>
                </div>
                <div className="mt-5 w-full flex px-12 justify-center text-general-gray text-lg md:text-2xl font-bold">
                  <span>{t('login.you_are_not_allow')}</span>
                </div>
                <div className="mt-5 px-4 sm:px-8 w-full flex flex-col items-start justify-start text-general-gray">
                  <ul className="w-full list-decimal text-sm md:text-xl whitespace-pre-line font-bold">
                    <li className="mb-4">{parse(t('login.not_allow.1'))}</li>
                    <li className="mb-4">{parse(t('login.not_allow.2'))}</li>
                    <li className="mb-4">{parse(t('login.not_allow.3'))}</li>
                    <li className="mb-10">{parse(t('login.not_allow.4'))}</li>
                  </ul>
                </div>
                <div className="w-full flex flex-col items-center justify-start">
                  <button type="submit" role="submit" className={classNames({
                    "px-24 pt-3 pb-2 text-center text-2xl md:text-4xl leading-none rounded-full font-bold text-white tracking-widest outline-none ": true,
                    "bg-primary hover:bg-red-700": !!agree && !!password,
                    "bg-button-bg pointer-events-none": !agree || !password
                  })}>{t('login.enter')}</button>
                </div>
              </div>

              <div className="max-w-38rem w-full mt-10">
                <div className="w-full text-button-bg font-bold text-md md:text-xl whitespace-pre-line">
                  {parse(t('login.please_note'))}
                </div>
              </div>
            </div>
          </form>
          <ErrorPopup
            open={openPopup}
            content={error}
            handleClosePopup={() => setOpenPopup(false)}
          />
        </div>
      </div>
    </div>
  )
}
export default LoginLayout
