import React from 'react'
import { classNames } from '../../utils'

const OnDemand = ({ t, i18n, video, onShowVideo }) => {
  const locale = i18n.language
  const videoUrl = video[`video_url_${locale}`]
  const thumbnailUrl = video['thumbnail_url']
  const topic = video[`topic_${locale}`]
  const speakerName = video[`speaker_name_${locale}`]
  const speakerType = video['speaker_type']
  const themeColor = speakerType === 'keynote' ? 'primary' : 'secondary'

  function handleOpenVideo() {
    onShowVideo(video)
  }

  if (!videoUrl) {
    return <div className=" flex flex-col w-239 mx-4 mb-8">
      <span
        onClick={event => { event.preventDefault() }}
        className={`relative`}
        style={{ display: 'inline-block', height: 138, width: 239 }}
      >
        <a href="#" onClick={handleOpenVideo}>
          {thumbnailUrl && <img src={thumbnailUrl} alt="" />}
        </a>
        <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full z-10 opacity-70 bg-white" />
        <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full z-20 text-gray font-bold text-xl">
          {t('coming_soon')}
        </div>
      </span>
      <div className="mt-2 -ml-2 w-64 flex justify-center text-center leading-tight text-gray font-bold text-sm whitespace-pre-line">
        {topic}
      </div>
      <div className={classNames({
        "-ml-2 w-64 flex justify-center text-center font-bold text-sm whitespace-pre-line leading-tight": true,
        "text-primary": themeColor === 'primary',
        "text-secondary": themeColor === 'secondary'
      })}>
        {speakerName}
      </div>
    </div>
  }

  return (
    <div className="flex flex-col w-239 mx-4 mb-8">
      <span
        onClick={handleOpenVideo}
        className={`wistia_embed wistia_async_${videoUrl} popover=true popoverContent=link popoverOverlayOpacity=0.8  popoverCaption=${encodeURIComponent(topic)} popoverAnimation=fade popoverAnimateThumbnail=true`}
        style={{ display: 'inline-block', height: 138, width: 239 }}
      >
        <a href="#" onClick={handleOpenVideo}>
          {thumbnailUrl && <img src={thumbnailUrl} alt="" />}
        </a>
      </span>
      <div className="mt-2 -ml-2 w-64 flex justify-center text-center leading-tight text-gray font-bold text-sm whitespace-pre-line">
        {topic}
      </div>
      <div className={classNames({
        "-ml-2 w-64 flex justify-center text-center font-bold text-sm whitespace-pre-line leading-tight": true,
        "text-primary": themeColor === 'primary',
        "text-secondary": themeColor === 'secondary'
      })}>
        {speakerName}
      </div>
    </div>
  )
}

export default OnDemand