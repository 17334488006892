import React, { useEffect, useState } from 'react'
import axios from 'utils/axios'
import rawAxios from 'axios'
import { classNames } from 'utils'
import OnDemandList from './OnDemandList'

const HomeIndex = ({ t, i18n }) => {
  const [data, setData] = useState({ keynoteSessions: [], pruSpeaksSessions: [] })
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('keynote')

  const { keynoteSessions, pruSpeaksSessions } = data

  useEffect(() => {
    async function fetchData() {
      if (loading) return
      setLoading(true)
      try {
        const resp = await rawAxios.get("/on_demands.json")
        setData({
          keynoteSessions: resp.data.keynote_videos,
          pruSpeaksSessions: resp.data.pru_speak_videos
        })
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  async function handleShowVideo(video) {
    try {
      await axios.get(`/on_demands/${video.uuid}`)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="text-primary font-bold text-2xl sm:text-4xl uppercase">
        {t('main_page.on_demand')}
      </div>
      <div className="w-full flex flex-col items-center mb-10 px-8">

        <div className="max-w-49rem w-full flex mt-5 mb-12 items-stretch">
          <div
            onClick={() => setTab('keynote')}
            className={classNames({
              'w-1/2 flex-grow-0 flex-shrink-0 flex justify-center items-center cursor-pointer font-bold text-md sm:text-2xl bg-lightGray  border-b-8 rounded-2xl rounded-r-none rounded-b-none': true,
              'text-gray': tab !== 'keynote',
              'text-primary border-primary': tab === 'keynote'
            })}>
            <div className="w-full h-full flex justify-center items-center text-center pt-4 pb-2 px-2 uppercase ">
              {t('main_page.keynote_sessions')}
            </div>
          </div>
          <div
            onClick={() => setTab('pru_speaks')}
            className={classNames({
              'w-1/2 flex-grow-0 flex-shrink-0 flex justify-center items-center cursor-pointer font-bold text-md sm:text-2xl bg-lightGray  border-b-8 rounded-2xl rounded-l-none rounded-b-none': true,
              'text-gray': tab !== 'pru_speaks',
              'text-primary border-primary': tab === 'pru_speaks'
            })}>
            <div className="w-full h-full flex justify-center items-center  text-center pt-4 pb-2 px-2 border-l border-gray-border uppercase">
              {t('main_page.pru_speaks_sessions')}
            </div>
          </div>
        </div>

        <div className={classNames({
          "w-full max-w-52rem flex-wrap justify-center": true,
          "flex": tab === 'keynote',
          "hidden": tab !== 'keynote',
        })}>
          <OnDemandList onDemands={keynoteSessions} t={t} i18n={i18n} onShowVideo={handleShowVideo} />
        </div>

        <div className={classNames({
          "w-full max-w-52rem flex-wrap justify-center": true,
          "flex": tab === 'pru_speaks',
          "hidden": tab !== 'pru_speaks',
        })}>
          <OnDemandList onDemands={pruSpeaksSessions} t={t} i18n={i18n} onShowVideo={handleShowVideo} />
        </div>
      </div>
    </>
  )
}

export default HomeIndex
